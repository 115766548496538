import { defineComponent as _defineComponent } from 'vue'
import { computed } from 'vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'CheckoutProducts',
  props: {
  item: {
    type: Object,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const props = __props

const placeholderImage = window.placeholderImage

const totalArea = computed(() => {
  const { base } = props.item

  if (!base.convertArea) return null

  return base.quantity * base.element.quantityInBox
})

const __returned__ = { props, placeholderImage, totalArea, computed }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})